.companyDetails {
  background-color: white;
  button {
    height: 40px;
    padding: 0 40px;
    span {
      font-size: @text-sm;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    border-bottom: @border;
    &__leftCol {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__img {
        width: 160px;
        height: 160px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &__info {
        margin-left: 40px;
        div {
          &:last-of-type {
            font-size: @text-sm;
            color: @primary-color;
            margin: 8px 0;
          }
        }
        p {
          color: @secondary-text;
          font-size: @text-sm;
          font-weight: 500;
        }
      }
    }
  }
  &__row {
    border-bottom: @border;
    display: flex;
    flex-direction: column;
    padding: 24px 24px;
    &__field {
      padding: 34px 24px;
      // margin-top: 34px;
      border-bottom:1px solid lightgray;
      padding: 2rem;
      display: flex;
      &__key {
        width: 336px;
        max-width: 336px;
        color: @secondary-text;
        font-size: @text-sm;
        font-weight: 400;
      }
      &__value {
        font-size: @text-md;
        font-weight: 500;
      }
    }
    &__col {
      display: flex;
      justify-content: space-between;
    }
  }
}

@hack: true; @import "./src/styles/theme.less";